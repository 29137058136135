import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import './ContactPage.css';  // Custom CSS for additional styling

const ContactPage: React.FC = () => {
    const [form, setForm] = useState({ name: '', email: '', message: '' });
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = () => {
        setSubmitted(true);
        // Handle form submission logic here
    };

    return (
        <div className="contact-page">
            <div className="contact-card p-shadow-5">
                <h2 className="contact-title">Get In Touch</h2>
                <p className="contact-subtitle">Feel free to reach out and let's create something amazing together!</p>

                <div className="p-mb-4 contact-field">
                    <label htmlFor="name" className="p-d-block contact-label">Name</label>
                    <InputText
                        id="name"
                        name="name"
                        value={form.name}
                        onChange={handleInputChange}
                        className={classNames({ 'p-invalid': submitted && !form.name })}
                    />
                    {submitted && !form.name && <small className="p-error">Name is required.</small>}
                </div>

                <div className="p-mb-4 contact-field">
                    <label htmlFor="email" className="p-d-block contact-label">Email</label>
                    <InputText
                        id="email"
                        name="email"
                        value={form.email}
                        onChange={handleInputChange}
                        className={classNames({ 'p-invalid': submitted && !form.email })}
                    />
                    {submitted && !form.email && <small className="p-error">Email is required.</small>}
                </div>

                <div className="p-mb-4 contact-field">
                    <label htmlFor="message" className="p-d-block contact-label">Message</label>
                    <InputTextarea
                        id="message"
                        name="message"
                        value={form.message}
                        onChange={handleInputChange}
                        rows={5}
                        className={classNames({ 'p-invalid': submitted && !form.message })}
                    />
                    {submitted && !form.message && <small className="p-error">Message is required.</small>}
                </div>

                <Button
                    label="Send Message"
                    icon="pi pi-send"
                    className="p-button-rounded p-button-lg contact-button"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default ContactPage;
