import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { supabaseClient } from '../../config/supabase-client';
import { AuthError, Session } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import Coopers_Rock_1 from '../../assets/Coopers_Rock_1.jpg';
import './SupaLogin.css';
const { REACT_APP_THINKHAUS_URL } = process.env

function Login() {
  const [email, setEmail] = useState<string>('');
  const [resetEmail, setResetEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(false);
  const [passReset, setPassReset] = useState(false);
  const [passConfirm, setPassConfirm] = useState(false);
  const [Error, setError] = useState<AuthError>();
  const navigate = useNavigate();

  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabaseClient.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

  }, []);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const { error } = await supabaseClient.auth.signInWithPassword({ email, password });
      if (error) throw error;
      navigate("/");
    } catch (err) {
      setError(err);
      console.error('Login error:', err);
    } finally {
      setEmail('');
      setPassword('');
      setLoading(false);
    }
  };

  const handleSendEmail = () => {
    setLoading(true);

    const options = { redirectTo: `${REACT_APP_THINKHAUS_URL}profile/passwordReset` };

    supabaseClient.auth.resetPasswordForEmail(resetEmail, options);

    setLoading(false);
    setPassReset(false);
    setPassConfirm(true)
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  if (loading) return <ProgressSpinner className='flex min-h-height items-center justify-content-center' />;

  return (
    <div
      className="flex min-h-height align-items-center justify-content-center background-image"
      style={{ backgroundImage: `url(${Coopers_Rock_1})`, height: '100vh' }}
    >
      <Card title="Login" style={{ width: '450px' }}>
        <div>
          <div className="p-fluid" >
            <div className="p-field" >
              <label htmlFor="email">Username or email address</label>
              <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="p-field" style={{ paddingBottom: '10px' }}>
              <label htmlFor="password">Password</label>
              <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleEnter} toggleMask feedback={false} />
            </div>
            <div className="p-field">
              <Button id="signin" label="Sign in" onClick={handleLogin} className="p-button-primary" />
              <Button label="Forgot password?" onClick={() => setPassReset(true)} className="p-button-text p-ml-2" />
            </div>
          </div>
        </div>
        {Error && (
          <div id='error' style={{ color: 'red', fontWeight: 'bold', paddingTop: '10px' }}>{Error.message}</div>
        )}
      </Card>

      <Dialog className="h-10rem w-15rem" visible={passReset} onHide={() => setPassReset(false)} header="Forgot Password">
        <div className="p-fluid">
          <div className="p-field p-grid p-justify-center">
            <span className="p-float-label mt-4" style={{ paddingBottom: '10px' }}>
              <InputText
                id="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                onKeyDown={(e) => { if (e.key === 'Enter') { handleSendEmail(); } }}
                className="w-full"
                autoFocus
              />
              <label htmlFor="email">Email</label>
            </span>
          </div>
          <div className="p-field p-grid p-justify-center">
            <Button
              label="Send Email"
              onClick={handleSendEmail}
              loading={loading}
              className="p-button-primary"
            />
          </div>
        </div>
      </Dialog>

      <Dialog className="" visible={passConfirm} onHide={() => setPassConfirm(false)} header="Email Sent!">
        <div className="p-fluid">
          Navigate to your email and click on the password reset link.
        </div>
      </Dialog>

    </div>
  );
}

export default Login;
