// import '98.css';

export const Retro = () => {
    return (
        <div className="window w-screen" style={{}}>
            <div className="title-bar">
                <div className="title-bar-text">Fly Fishing Enthusiast's Haven</div>
                <div className="title-bar-controls">
                    <button aria-label="Minimize"></button>
                    <button aria-label="Maximize"></button>
                    <button aria-label="Close"></button>
                </div>
            </div>
            <div className="window-body">
                <div className="retro-header">
                    <h1 style={{ textAlign: 'center', color: 'blue' }} className="marquee-text ">
                        Welcome to Fly Fishing Enthusiast's Haven!
                    </h1>
                    <hr />
                    <p style={{ fontSize: '12px', color: 'gray', textAlign: 'center' }}>
                        <strong>Visitor Counter:</strong> <span style={{ color: 'red' }}>001234</span> 🎣
                    </p>
                </div>

                <div className="content">

                    <div className='flex iframe-container'>
                        <iframe src="https://giphy.com/embed/2csuIJj6TmuKA" width="320" height="480" style={{}} className="giphy-embed uniform-iframe" allowFullScreen title="River flow 1"></iframe>
                        <iframe src="https://giphy.com/embed/52Ywm818WNeuI" width="320" height="480" style={{}} className="giphy-embed uniform-iframe" allowFullScreen title="River flow 2"></iframe>
                        <iframe src="https://giphy.com/embed/7LsYKNn60KhUs" width="320" height="480" style={{}} className="giphy-embed uniform-iframe" allowFullScreen title="River flow 3"></iframe>
                        <iframe src="https://giphy.com/embed/VehoU0h2Rl8Gc" width="320" height="480" style={{}} className="giphy-embed uniform-iframe" allowFullScreen title="River flow 4"></iframe>
                        <iframe src="https://giphy.com/embed/IoLzJxVrDp9sc" width="320" height="480" style={{}} className="giphy-embed uniform-iframe" allowFullScreen title="River flow 5"></iframe>
                    </div>

                    <p style={{ fontFamily: 'Comic Sans MS', color: '#008080', fontSize: '14px' }}>
                        Welcome to my personal website all about <strong>fly fishing</strong>! 🐟 I've been fly fishing since I was a kid but took a
                        hiatus up until recent. Come here to track tips, tricks, and stories as I learn and adventure!
                    </p>

                    <div style={{ backgroundColor: '#E0E0E0', padding: '10px', marginTop: '20px' }}>
                        <h2 style={{ color: 'navy' }}>Pages</h2>
                        <ul>
                            <li>
                                <a href="#tips" style={{ textDecoration: 'underline', color: 'blue' }}>
                                    Fly Fishing Tips
                                </a>
                            </li>
                            <li>
                                <a href="#gear" style={{ textDecoration: 'underline', color: 'blue' }}>
                                    Gear Recommendations
                                </a>
                            </li>
                            <li>
                                <a href="#locations" style={{ textDecoration: 'underline', color: 'blue' }}>
                                    Best Fly Fishing Locations
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div id="sign-in-book" style={{ marginTop: '20px', backgroundColor: '#FFF8DC', padding: '10px' }}>
                        <h2 style={{ color: 'green' }}>Sign My Guestbook!</h2>
                        <form>
                            <label htmlFor="name">Name: </label>
                            <input type="text" id="name" name="name" />
                            <br /><br />
                            <label htmlFor="comments">Comments: </label>
                            <textarea id="comments" name="comments" rows={4} cols={40} ></textarea>
                            <br /><br />
                            <button type="submit" className="button">Sign</button>
                        </form>
                    </div>

                    <div style={{ marginTop: '20px', padding: '10px', border: '2px dashed #c0c0c0' }}>
                        <h2 style={{ fontFamily: 'Courier New', color: '#000080' }}>My Favorite Fly Fishing Links</h2>
                        <ul>
                            <li><a href="https://edsflyshop.com/" target="_blank" rel="noreferrer" style={{ color: 'purple' }}>edsflyshop.com/</a></li>
                            <li><a href="https://www.orvis.com/fly-fishing-3" target="_blank" rel="noreferrer" style={{ color: 'purple' }}>Orvis.com</a></li>
                            <li><a href="https://www.elkfisher.com/" target="_blank" rel="noreferrer" style={{ color: 'purple' }}>Elk Fisher</a></li>
                        </ul>
                    </div>

                    <div style={{ backgroundColor: '#F0F0F0', marginTop: '20px', padding: '10px', textAlign: 'center' }}>
                        <h2 style={{ fontFamily: 'Courier New', color: 'blue' }}>Join Our Newsletter!</h2>
                        <p>Subscribe to get the latest fly fishing tips, tricks, and gear recommendations right in your inbox.</p>
                        <form>
                            <label htmlFor="email">Email: </label>
                            <input type="email" id="email" name="email" />
                            <br /><br />
                            <button type="submit" className="button">Subscribe</button>
                        </form>
                    </div>

                    <footer style={{ marginTop: '20px', backgroundColor: '#E0E0E0', padding: '10px', textAlign: 'center', fontSize: '12px' }}>
                        <p>© 1999 Fly Fishing Enthusiast's Haven - All Rights Reserved</p>
                        <p><a href="#sign-in-book" style={{ color: 'blue' }}>Sign the Guestbook</a> | <a href="/contact" style={{ color: 'blue' }}>Contact Me</a></p>
                    </footer>
                </div>
            </div>
        </div>
    );
};