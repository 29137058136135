import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import ResetPasswordPage from "./components/Auth/ResetPasswordPage";
import NavBar from "./components/Home/NavBar";
import SupaLogin from "./components/Login/SupaLogin";
import { UnderConstruction } from "./components/UnderConstruction/UnderConstruction";
import { Homepage } from "./components/Homepage/Homepage";
import ContactPage from "./components/ContactPage/ContactPage";
import PerlinNoise from "./components/Processing/PerlinNoise";
import Fibonacci from "./components/Processing/Fibonacci/Fibonacci";
import { Retro } from "./components/Retro/Retro";
import { BlogProvider } from './context/BlogContext';
import BlogWrapper from "./components/Blog/BlogWrapper";

const App = () => {
    return (
        <div className="min-width-scroll">
            <Routes>
                <Route path="*" element={<Navigate to="/home" replace />} />
                <Route path='/login' element={<><NavBar /> <SupaLogin /> </>} />
                <Route path='/home' element={<><NavBar /><Homepage /></>} />
                <Route path='/contact' element={<><NavBar /><ContactPage /></>} />
                <Route path='/processing/perlinNoise' element={<><NavBar /> <PerlinNoise /> </>} />
                <Route path='/processing/fibonacci' element={<><NavBar /> <Fibonacci /> </>} />
                <Route path='/profile/passwordReset' element={<ProtectedRoute> <ResetPasswordPage /> </ProtectedRoute>} />
                <Route path='/retro' element={<> <NavBar /> <Retro /></>} />
                <Route path='/blog/*' element={<BlogProvider><BlogWrapper /></BlogProvider>} />
            </Routes>

            <p className='text-xs text-right'>Version {process.env.REACT_APP_GIT_TAG}</p>
        </div>
    )
}

export default App;