import React from 'react';
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import { Menubar } from 'primereact/menubar';
import fliegel_logo from '../../assets/Fliegel_Logo.png';
import "./HomePage.css";

const NavBar: React.FC = () => {
    const { user, signOut, session, role } = useAuth();
    const navigate = useNavigate();

    const isAuthenticated = session !== null;

    const signIn = () => {
        navigate('/login');
    }

    const menuItems = [
        // {
        //     label: 'Projects',
        //     icon: 'pi pi-search',
        //     items: [
        //         {
        //             label: 'Perlin Ocean Render',
        //             icon: 'pi pi-bolt',
        //             command: () => navigate("/processing/perlinNoise"),
        //             id: 'processing-id'
        //         },
        //         {
        //             label: 'Fibonacci Spiral',
        //             icon: 'pi pi-server',
        //             command: () => navigate("/processing/fibonacci"),
        //             id: 'processing-fib'
        //         },
        //         {
        //             label: 'Retro',
        //             icon: 'pi pi-pencil',
        //             command: () => navigate("/retro"),
        //             id: 'retro'
        //         },
        //         {
        //             label: 'Templates',
        //             icon: 'pi pi-palette',
        //             items: [
        //                 {
        //                     label: 'Apollo',
        //                     icon: 'pi pi-palette'
        //                 },
        //                 {
        //                     label: 'Ultima',
        //                     icon: 'pi pi-palette'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        {
            label: 'Contact',
            icon: 'pi pi-envelope',
            command: () => navigate("/contact"),
        },
        {
            label: 'Blog',
            icon: 'pi pi-book',
            command: () => navigate("/blog"),
        },
        ...(isAuthenticated) ?
            [
                {
                    label: 'Blog Editor',
                    icon: 'pi pi-pencil',
                    command: () => navigate("/blog/admin"),
                    id: 'admin'
                }
            ]
            :
            []
    ];


    return (
        <>
            <Menubar
                model={menuItems}
                start={
                    <button
                        onClick={() => navigate("/home")}
                        className="p-link"
                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                        <img src={fliegel_logo} alt="Fliegel Logo" className="mr-2" style={{ height: '40px' }} />
                    </button>
                }
                end={
                    <div>
                        {(isAuthenticated) ?
                            <button className="p-button button-secondary" onClick={signOut}>
                                Logout
                            </button>
                            :
                            <button className="p-button button-secondary" onClick={signIn}>
                                Login
                            </button>}
                    </div>
                }
            />
        </>
    )
};

export default NavBar;
