import { Session, User } from '@supabase/supabase-js';
import { useContext, useState, useEffect, createContext } from 'react';
import { supabaseClient } from '../config/supabase-client';
import { jwtDecode } from 'jwt-decode';

// create a context for authentication
const AuthContext = createContext<{ session: Session | null | undefined, user: User | null | undefined, role: String | null | undefined, signOut: () => void }>({ session: null, user: null, role: null, signOut: () => {} });

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<User>()
    const [session, setSession] = useState<Session | null>();
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState<string | undefined>();

    useEffect(() => {
        const setData = async () => {
            const { data: { session }, error } = await supabaseClient.auth.getSession();
            if (error) throw error;
            if (session) {
                const jwt: { [key: string]: any } = jwtDecode(session.access_token);
                const userRole = jwt.user_role
                setRole(userRole);
            }
            setSession(session)
            setUser(session?.user)
            setLoading(false);
        };

        const { data: listener } = supabaseClient.auth.onAuthStateChange((_event, session) => {
            if (session) {
                const jwt: { [key: string]: any } = jwtDecode(session.access_token);
                const userRole = jwt.user_role
                setRole(userRole);
            }
            setSession(session);
            setUser(session?.user)
            setLoading(false)
        });

        setData();

        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    const handleSignOut = async () => {
        const { error } = await supabaseClient.auth.signOut();
        if (error) console.error('Logout error:', error);
      };

    const value = {
        session,
        user,
        role,
        signOut: handleSignOut,
    };

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

// export the useAuth hook
export const useAuth = () => {
    return useContext(AuthContext);
};