import React, { useEffect, useRef } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import resumePDF from '../../assets/Jack-Fliegel-Resume.pdf';
import Coopers_Rock_1 from '../../assets/Coopers_Rock_1.jpg';
import butterfly_pattern from '../../assets/butterfly_pattern.png';
import milky_way_1 from '../../assets/milky_way_1.jpg';
import './Homepage.css';

export const Homepage: React.FC = () => {
    const heroWrapperRef = useRef<HTMLDivElement>(null);

    return (
        <ParallaxProvider>
            <div className="homepage">
                <div ref={heroWrapperRef} className="hero-wrapper">
                    <Parallax className="hero hero-background" style={{ backgroundImage: `url(${butterfly_pattern})` }}>
                        <div className="hero-content ">
                            <h1 className="hero-title">FLIEGEL.APP</h1>
                        </div>
                    </Parallax>
                    {/* <div className="inverted-strip diagonal-strip-1" />
                    <div className="inverted-strip diagonal-strip-2" />
                    <div className="inverted-strip diagonal-strip-3" /> */}
                    <div className="inverted-strip diagonal-strip-1 bottom-strip" />
                    <div className="inverted-strip diagonal-strip-2 center-strip" />
                </div>


                {/* About Me Section */}
                <section id='about' className="about-me-section">
                    <Parallax
                        speed={15}
                        className="hero hero-background space-picture"
                        style={{ backgroundImage: `url(${milky_way_1})` }}
                    >
                        <div className="about-me-content inverted-text">
                            <h1>
                                <em>To create is to abstract meaning and make useful</em>
                            </h1>
                        </div>
                    </Parallax>
                </section>

                {/* Resume Section */}
                <section id='resume' className="resume-section">
                    <Parallax
                        speed={-2}
                        className="hero hero-background space-picture"
                        style={{ backgroundImage: `url(${Coopers_Rock_1})` }}

                    >
                        <div className="resume-content">
                            <iframe
                                src={resumePDF}
                                width="100%"
                                height="600px"
                                title="Resume"
                                style={{ border: "none" }}
                            />
                        </div>
                    </Parallax>
                </section>
            </div>
        </ParallaxProvider>

    );
};
