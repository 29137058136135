import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBlogContext } from '../../../context/BlogContext';
import DOMPurify from 'dompurify';
import './BlogPost.css';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

export const BlogPost: React.FC = () => {
    const { author, title, id } = useParams<{ author: string; title: string, id: string }>();
    const { blogs, fetchBlogContent } = useBlogContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    // Find the blog by ID
    const blog = blogs.find(b => b.id === id);

    useEffect(() => {
        setLoading(true);

        const loadContent = async () => {
            if (!blog?.content && blog?.id) {
                fetchBlogContent(blog.id);
            } 
        };

        loadContent();
        setLoading(false);
    }, [blog, author, id, navigate, fetchBlogContent]);

    const sanitizedContent = DOMPurify.sanitize(blog?.content) || "No blog content found";

    return (
        <div className='w-screen surface-ground'>
            <Button
                label='back'
                icon='pi pi-chevron-left'
                iconPos='left'
                onClick={() => navigate('/blog')}
                className='p-button-rounded m-4 mb-0'
                severity='secondary'
            />
            {
                loading
                    ?
                    <article className="blog-post h-full surface-ground">
                        <ProgressSpinner className='flex justify-content-center' />
                    </article>
                    :
                    <article className="blog-post h-full surface-ground">
                        <header>
                            <h1>{blog?.title}</h1>
                            <p className="author">
                                By {blog?.author || "John Doe"}, published{" "}
                                {new Date(blog?.published_at).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                })}
                            </p>
                        </header>
                        <div className="content" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    </article>

            }

        </div>
    );
};
