import React from 'react';
import Sketch from 'react-p5';

const Fibonacci = () => {
    const CANVAS_WIDTH = 1920;
    const CANVAS_HEIGHT = 1080;
    let fibs = [1, 1];
    let scale = 1;
    let minScale;
    let t = 0;

    let inc = 1;
    let scl = 20;
    let cols, rows;
    let zoff = 0;
    let particles = [];
    let flowfield = [];
    let flowfieldInitialized = false;
    const colors = ['#006600', '#4C9900', '#66CC00', '#195611'];

    // Create a buffer for Fibonacci arcs
    let fibBuffer;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(CANVAS_WIDTH, CANVAS_HEIGHT).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES);

        cols = p5.floor(p5.width / scl);
        rows = p5.floor(p5.height / scl);
        flowfield = new Array(cols * rows);

        // Initialize flowfield once
        if (!flowfieldInitialized) {
            initializeFlowfield(p5);
            flowfieldInitialized = true;
        }

        // Create a buffer for the Fibonacci arcs to avoid redrawing them every frame
        fibBuffer = p5.createGraphics(CANVAS_WIDTH, CANVAS_HEIGHT);
        fibBuffer.angleMode(p5.DEGREES);
        initFibs();
        drawFibonacciArcs(fibBuffer);
        setMinScale();
    };

    const draw = (p5) => {
        t += 1;
        p5.background(255);

        // Instead of recalculating Fibonacci arcs, draw them from the buffer
        p5.image(fibBuffer, 0, 0);

        // Handle flowfield particles
        updateFlowfield(p5);
        updateParticles(p5);

        // Scale logic
        if (scale <= minScale) {
            fibs = [1, 1];
            initFibs();
            scale = 1;
        } else {
            scale *= 0.99;
        }
    };

    // Initialize Fibonacci arcs and draw them once on the buffer
    const drawFibonacciArcs = (buffer) => {
        buffer.push();
        buffer.translate(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);
        for (let i = 0; i < fibs.length; i++) {
            const fib = fibs[i] * scale;
            buffer.arc(fib, 0, 2 * fib, 2 * fib, 90, 180);
            buffer.noFill();
            buffer.translate(fib, fib);
            buffer.rotate(-90);
        }
        buffer.pop();
    };

    const initFibs = () => {
        fibs = [1, 1];
        for (let i = 0; i < 25; i++) {
            addFib();
        }
    };

    const addFib = () => {
        const fibLen = fibs.length;
        fibs.push(fibs[fibLen - 1] + fibs[fibLen - 2]);
    };

    const setMinScale = () => {
        const fibLen = fibs.length;
        minScale = fibs[fibLen - 5] / fibs[fibLen - 1];
    };

    const initializeFlowfield = (p5) => {
        let yoff = 0;
        for (let y = 0; y < rows; y++) {
            let xoff = 0;
            for (let x = 0; x < cols; x++) {
                let index = x + y * cols;
                let angle = p5.noise(xoff, yoff, zoff) * p5.TWO_PI;
                let v = p5.constructor.Vector.fromAngle(angle);
                v.setMag(0.1);
                flowfield[index] = v;
                xoff += inc;
            }
            yoff += inc;
        }
        zoff += 0.1;
    };

    const updateFlowfield = (p5) => {
        // Recalculate the flowfield values only when necessary
        if (t % 100 === 0) {
            initializeFlowfield(p5);
        }
    };

    const updateParticles = (p5) => {
        for (let i = 0; i < particles.length; i++) {
            particles[i].follow(flowfield);
            particles[i].update();
            particles[i].edges();
            particles[i].show();
        }
    };

    return (
        <div>
            <Sketch setup={setup} draw={draw} />
        </div>
    );
};

export default Fibonacci;
