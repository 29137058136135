import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useBlocker } from 'react-router-dom';
import { Blog, useBlogContext } from '../../../context/BlogContext';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import Quill from 'quill';
import { Toast } from 'primereact/toast';
import { v4 as uuidv4 } from 'uuid';
import { InputTextarea } from 'primereact/inputtextarea';
import { supabaseClient } from '../../../config/supabase-client';

export const BlogEditor: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { blogs, draftBlogs, updateBlog, addBlog } = useBlogContext();
    const [blog, setBlog] = useState({ id: '', title: '', content: '', is_draft: true, summary: '', author: '', published_at: null, slug: '' });
    const [isUnsaved, setIsUnsaved] = useState(false);
    const toast = useRef<Toast>(null);
    const quillRef = useRef(null);

    let foundBlog;

    useEffect(() => {
        foundBlog = [...blogs, ...draftBlogs].find(b => b.id === id);
        if (foundBlog) {
            setBlog(foundBlog);
        }
    }, [id, blogs, draftBlogs]);

    useBlocker(
        ({ currentLocation, nextLocation }) => {
            const isInBlogEditor = currentLocation.pathname.startsWith('/blog/admin/edit/');
            if (isUnsaved && isInBlogEditor && currentLocation.pathname !== nextLocation.pathname) {
                const userConfirmed = window.confirm("You have unsaved changes. Are you sure you want to leave?");
                return !userConfirmed;
            }
            return false;
        }
    );

    const handlePublish = () => {
        setBlog({
            ...blog,
            is_draft: !blog.is_draft,
            published_at: !blog.is_draft ? null : new Date().toString()
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | EditorTextChangeEvent, property: keyof Blog) => {
        if ('htmlValue' in e) {
            setBlog(prev => ({
                ...prev,
                [property]: e.htmlValue || ''
            }));

        }
        else {
            setBlog(prev => ({
                ...prev,
                [property]: e.target.value
            }));
        }

        setIsUnsaved(true);
    }

    const handleSave = () => {
        if (blog.id) {
            updateBlog(blog);
        } else {
            const newBlog = {
                ...blog,
                id: uuidv4()
            };
            addBlog(newBlog);
            setBlog(newBlog);  // Update the state with the new ID
            navigate(`/blog/admin/edit/${newBlog.id}`, { replace: true });  // Update URL
        }

        if (toast.current) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Blog saved successfully', life: 3000 });
        }

        setIsUnsaved(false);
    };

    return (
        <div className="p-grid">
            <Toast ref={toast} />
            <div className="p-col-12">
                <Button icon="pi pi-arrow-left" label="Back to Admin Panel" onClick={() => navigate('/blog/admin')} className="p-button-secondary p-mb-3" />
            </div>
            <div className="p-col-12">
                <h1>Edit Blog</h1>
            </div>
            <div className="flex flex-row w-full justify-between my-4">
                <span className="p-float-label w-6 mr-4">
                    <InputText id="title" value={blog.title} onChange={e => handleChange(e, 'title')} className='w-full' />
                    <label htmlFor="title">Title</label>
                </span>
                <span className="p-float-label w-6">
                    <InputText id="slug" value={blog.slug} onChange={(e) => handleChange(e, 'slug')} className='w-full' />
                    <label htmlFor="slug">Slug</label>
                </span>
            </div>
            <div className="p-col-12 p-mb-3 flex flex-row w-full my-4">
                <span className="p-float-label">
                    <InputTextarea id="summary" value={blog.summary} onChange={(e) => handleChange(e, 'summary')} className='' />
                    <label htmlFor="summary">Summary</label>
                </span>
            </div>

            <div className="p-col-12 p-mb-3">
                <Editor
                    style={{ height: '320px' }}
                    value={blog.content}
                    onTextChange={(e) => handleChange(e, 'content')}
                    ref={quillRef}
                />
            </div>
            <div className="p-col-12">
                <Button label="Save" icon="pi pi-check" onClick={handleSave} className="p-button-success p-button-rounded m-2" disabled={!isUnsaved} />
                <Button label={blog.is_draft ? "Publish" : "Unpublish"} icon={blog.is_draft ? "pi pi-send" : "pi pi-file"} onClick={handlePublish} className="p-button-rounded m-2" />
            </div>
        </div>
    );
};
