import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from '../Home/NavBar';
import ProtectedRoute from '../Auth/ProtectedRoute';
import { BlogProvider } from '../../context/BlogContext';
import { BlogEditor } from './BlogEditor/BlogEditor';
import { AdminPanel } from '../AdminPanel/AdminPanel';
import { BlogList } from './BlogList/BlogList';
import { BlogPost } from './BlogPost/BlogPost';

const GeneratorWrapper = () => {

    return (
        <>
            <NavBar />
            <Routes>
                <Route
                    path='admin'
                    element={
                        <ProtectedRoute><AdminPanel />  </ProtectedRoute>
                    }
                />
                <Route
                    path="admin/edit/:id"
                    element={
                        <ProtectedRoute> <BlogEditor /> </ProtectedRoute>
                    }
                />
                <Route
                    path=""
                    element={
                        <BlogList />
                    }
                />
                {/* <Route
                    path=":title"
                    element={
                        <BlogPost />
                    }
                /> */}
                <Route 
                    path=':id'
                    element={
                        <BlogPost />
                    }
                />
            </Routes>
        </>
    );
};

export default GeneratorWrapper;
