import React, { useState, useEffect } from "react";
import { useBlogContext } from "../../context/BlogContext";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";

export const AdminPanel: React.FC = () => {
    const { blogs, setBlogs, draftBlogs, setDraftBlogs, addBlog, updateBlog, deleteBlog, setAsDraft, publishDraft, fetchAllBlogs } = useBlogContext();
    const [selectedBlog, setSelectedBlog] = useState<any>(null);
    const [isNewBlogDialogVisible, setIsNewBlogDialogVisible] = useState(false);
    const [newBlog, setNewBlog] = useState({ title: '', content: '', author: '', summary: '' });

    const navigate = useNavigate();

    useEffect(() => {
        fetchAllBlogs();
    }, [])

    const handleSetAsDraft = (id) => {
        setAsDraft(id);
        const updatedBlogs = blogs.map(blog =>
            blog.id === id ? { ...blog, is_draft: true } : blog
        );
        setBlogs(updatedBlogs); // Hypothetical context update function
    };

    const setPublishDraft = (id) => {
        publishDraft(id);
        const updatedDraftBlogs = draftBlogs.map(draft =>
            draft.id === id ? { ...draft, is_draft: false } : draft
        );
        setDraftBlogs(updatedDraftBlogs); // Hypothetical context update function
    };

    const handleCreateNewBlog = () => {
        addBlog({
            id: uuidv4(),
            title: newBlog.title,
            content: newBlog.content,
            is_draft: true,
            summary: null,
            author: newBlog?.author || '',
            published_at: null,
            slug: 'untitled-slug'
        });
        setIsNewBlogDialogVisible(false);
        setNewBlog({ title: '', content: '', author: '', summary: '' });
    };

    const actionTemplate = (rowData: any) => {
        return (
            <div className="text-right">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => navigate(`/blog/admin/edit/${rowData.id}`)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => deleteBlog(rowData.id)} />
                {rowData.is_draft ?
                    <Button icon="pi pi-check" className="p-button-rounded p-button-info" onClick={() => setPublishDraft(rowData.id)} /> :
                    <Button icon="pi pi-file" className="p-button-rounded p-button-secondary" onClick={() => handleSetAsDraft(rowData.id)} />
                }
            </div>
        );
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <h1>Blog Management</h1>
            </div>
            <div className="p-col-12">
                <Button label="Create New Blog" icon="pi pi-plus" onClick={() => navigate('/blog/admin/edit/new')} />
            </div>
            <div className="p-col-6">
                <h2>Published Blogs</h2>
                <DataTable value={blogs}>
                    <Column field="title" header="Title" />
                    <Column body={actionTemplate} />
                </DataTable>
            </div>
            <div className="p-col-6">
                <h2>Draft Blogs</h2>
                <DataTable value={draftBlogs}>
                    <Column field="title" header="Title" />
                    <Column body={actionTemplate} />
                </DataTable>
            </div>

            <Dialog header="Create New Blog" visible={isNewBlogDialogVisible} style={{ width: '50vw' }} modal onHide={() => setIsNewBlogDialogVisible(false)}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="title">Title</label>
                        <InputText id="title" value={newBlog.title} onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="content">Content</label>
                        <InputTextarea id="content" value={newBlog.content} onChange={(e) => setNewBlog({ ...newBlog, content: e.target.value })} rows={5} />
                    </div>
                </div>
                <Button label="Create" icon="pi pi-check" onClick={handleCreateNewBlog} />
            </Dialog>

            <Dialog header="Edit Blog" visible={!!selectedBlog} style={{ width: '50vw' }} modal onHide={() => setSelectedBlog(null)}>
                {selectedBlog && (
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="editTitle">Title</label>
                            <InputText id="editTitle" value={selectedBlog.title} onChange={(e) => setSelectedBlog({ ...selectedBlog, title: e.target.value })} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="editContent">Content</label>
                            <InputTextarea id="editContent" value={selectedBlog.content} onChange={(e) => setSelectedBlog({ ...selectedBlog, content: e.target.value })} rows={5} />
                        </div>
                        <Button label="Save" icon="pi pi-check" onClick={() => {
                            updateBlog(selectedBlog);
                            setSelectedBlog(null);
                        }} />
                    </div>
                )}
            </Dialog>
        </div>
    );
};
