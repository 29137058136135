import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Tooltip } from 'primereact/tooltip';
import { useBlogContext } from '../../../context/BlogContext';

import './BlogList.css';

export const BlogList: React.FC = () => {
    const [layout, setLayout] = useState<'list' | 'grid'>('list');
    const { blogs } = useBlogContext();
    const [publishedBlogs, setPublishedBlogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const filteredBlogs = blogs.filter(blog => !blog.is_draft);
        setPublishedBlogs(filteredBlogs);
    }, [blogs]);

    const handleReadMore = (id: string) => {
        // const urlTitle = title.toLowerCase().replace(/\s+/g, '-');
        // const urlSlug = slug.toLowerCase().replace(/\s+/g, '-') || 'test';
        navigate(`/blog/${id}`);
    };

    const itemTemplate = (blog) => (
        <div className="blog-list-item w-full">
            <Button
                label={blog.title}
                className="p-button-text w-full"
                onClick={() => handleReadMore(blog.id)}
                tooltip={`Title: ${blog.title} \nAuthor: ${blog.author || "John Doe"} \nPublished: ${new Date(blog.created_at).toLocaleDateString()} \nSummary: ${blog.summary}`}
                tooltipOptions={{ position: 'top', showDelay: 500, className: 'custom-tooltip' }}
            />
        </div>
    );

    return (
        <div className="blog-list-container">
            <h1 className="blog-list-title">Latest Articles</h1>
            <div className="blog-grid">
                {blogs.slice(0, 3).map((blog) => (
                    <Card key={blog.id} className="blog-card">
                        <h2>{blog.title}</h2>
                        <p className="blog-excerpt">{blog?.summary || 'Article does not have a summary I guess you will just have to read it.'}</p>
                        <Button
                            label="Read More"
                            className="p-button-text"
                            onClick={() => handleReadMore(blog.id)}
                        />
                    </Card>
                ))}
                { !blogs.length && <h3>No articles found</h3>}
            </div>
            <DataView
                value={blogs.slice(3)}
                layout={layout}
                itemTemplate={itemTemplate}
                className="blog-data-view"
                emptyMessage=' '
            >
                <DataViewLayoutOptions
                    layout={layout}
                    onChange={(e) => setLayout(e.value as 'list' | 'grid')}
                />
            </DataView>
        </div>
    );
};
