import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { supabaseClient } from '../config/supabase-client';
import { useAuth } from "./AuthProvider";

export interface Blog {
    id: string;
    title: string;
    content?: string;
    is_draft: boolean;
    summary?: string;
    author?: string;
    published_at?;
    slug?;
}

interface BlogContextType {
    blogs: Blog[];
    setBlogs: React.Dispatch<React.SetStateAction<Blog[]>>;
    draftBlogs: Blog[];
    setDraftBlogs: React.Dispatch<React.SetStateAction<Blog[]>>;
    addBlog: (blog: Blog) => void;
    updateBlog: (blog: Blog) => void;
    deleteBlog: (id: string) => void;
    setAsDraft: (id: string) => void;
    publishDraft: (id: string) => void;
    fetchBlogContent: (id: string) => void;
    fetchAllBlogs: () => void;
}

const BlogContext = createContext<BlogContextType | undefined>(undefined);

export const BlogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [draftBlogs, setDraftBlogs] = useState<Blog[]>([]);
    const { user } = useAuth();

    useEffect(() => {
        if (!blogs.length) {
            fetchBlogs_public();
        }
    }, [user]);

    const fetchBlogs_public = async () => {
        const { data: publicBlogs, error } = await supabaseClient
            .from('blogs')
            .select('id, title, is_draft, summary, author, published_at')
            .eq('is_draft', false)
            .order('published_at', { ascending: false });

        if (error) console.error('Error fetching public blogs:', error);
        else setBlogs(publicBlogs || []);
    };

    const fetchAllBlogs = async () => {
        const { data: blogsData, error: publishedError } = await supabaseClient
            .from('blogs')
            .select('*')
            .eq('user_id', user.id)
            .order('published_at', { ascending: false });

        if (publishedError) console.error('Error fetching published blogs:', publishedError);

        setBlogs(blogsData.filter(blog => !blog.is_draft) || []);
        setDraftBlogs(blogsData.filter(blog => blog.is_draft) || []);
    };

    const fetchBlogContent = async (id) => {
        const { data: BlogContent, error: fetchError } = await supabaseClient
            .from('blogs')
            .select('content')
            .eq('id', id)
            .limit(1);
    
        if (fetchError) console.error(fetchError);
    
        setBlogs(prevBlogs => {
            return prevBlogs.map((blog) => { // find the blog by id and set its content
                if (blog.id === id) {
                    blog.content = BlogContent[0]?.content || "No blog content found."
                    return blog
                }
                return blog
            })
        })
    }

    const addBlog = async (blog: Blog) => {
        const { data, error } = await supabaseClient
            .from('blogs')
            .insert([{ ...blog, user_id: user.id }]);

        if (error) console.error('Error adding blog:', error);
        // else fetchBlogs();
    };

    const updateBlog = async (updatedBlog: Blog) => {
        const { data, error } = await supabaseClient
            .from('blogs')
            .update(updatedBlog)
            .eq('id', updatedBlog.id)
            .eq('user_id', user.id);

        if (error) console.error('Error updating blog:', error);
        // else fetchBlogs();
    };

    const deleteBlog = async (id: string) => {
        const { data, error } = await supabaseClient
            .from('blogs')
            .delete()
            .eq('id', id)
            .eq('user_id', user.id);

        if (error) console.error('Error deleting blog:', error);
        // else fetchBlogs();
    };

    const setAsDraft = async (id: string) => {
        const { data, error } = await supabaseClient
            .from('blogs')
            .update({ is_draft: true, published_at: null })
            .eq('id', id)
            .eq('user_id', user.id);

        if (error) console.error('Error setting blog as draft:', error);
        // else fetchBlogs();
    };

    const publishDraft = async (id: string) => {
        const currentDate = new Date().toISOString();

        const { data, error } = await supabaseClient
            .from('blogs')
            .update({ is_draft: false, published_at: currentDate })
            .eq('id', id)
            .eq('user_id', user.id);

        if (error) console.error('Error publishing draft:', error);
        // else fetchBlogs();
    };

    const values = useMemo(() => ({
        blogs, setBlogs,
        draftBlogs, setDraftBlogs,
        addBlog, 
        updateBlog, 
        deleteBlog, 
        setAsDraft, 
        publishDraft, 
        fetchBlogContent, 
        fetchAllBlogs
    }), [
        blogs, setBlogs,
        draftBlogs, setDraftBlogs,
        addBlog, 
        updateBlog, 
        deleteBlog, 
        setAsDraft, 
        publishDraft, 
        fetchBlogContent, 
        fetchAllBlogs
    ])

    return (
        <BlogContext.Provider value={values}>
            {children}
        </BlogContext.Provider>
    );
};

export const useBlogContext = () => {
    const context = useContext(BlogContext);
    if (context === undefined) {
        throw new Error('useBlogContext must be used within a BlogProvider');
    }
    return context;
};
