import React from 'react';
import ReactDOM from 'react-dom/client'
import '/node_modules/primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "./utils/theme.css";
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import 'primeicons/primeicons.css';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

const root = ReactDOM.createRoot(document.getElementById('root'));// Function to get nonce dynamically from the meta tag

const getNonceFromMetaTag = () => {
  return document.querySelector('meta[name="csp-nonce"]')?.getAttribute('content') || 'default-nonce';
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />
  }
]);

const RootComponent = () => {
  const nonce = getNonceFromMetaTag();  // Get the nonce from meta tag dynamically

  return (
    <PrimeReactProvider value={{ nonce }}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </PrimeReactProvider>
  );
};

root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
