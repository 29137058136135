import React, { useState, useRef } from 'react';
import Sketch from 'react-p5';
import p5Types from 'p5'; // Import this to get typings for p5.js

const Particle = (p5: p5Types, width: number, height: number, scl: number) => {
    const pos = p5.createVector(p5.random(width), p5.random(height));
    const vel = p5.createVector(0, 0);
    const acc = p5.createVector(0, 0);
    const maxspeed = 2;

    let prevPos = pos.copy();

    const update = () => {
        vel.add(acc);
        vel.limit(maxspeed);
        pos.add(vel);
        acc.mult(0);
    };

    const follow = (vectors: p5Types.Vector[], cols: number) => {
        const x = p5.floor(pos.x / scl);
        const y = p5.floor(pos.y / scl);
        const index = x + y * cols;
        const force = vectors[index];
        applyForce(force);
    };

    const applyForce = (force: p5Types.Vector) => {
        acc.add(force);
    };

    const show = () => {
        p5.stroke(0, 5);
        p5.strokeWeight(1);
        p5.line(pos.x, pos.y, prevPos.x, prevPos.y);
        updatePrev();
    };

    const updatePrev = () => {
        prevPos.x = pos.x;
        prevPos.y = pos.y;
    };

    const edges = () => {
        if (pos.x > width) {
            pos.x = 0;
            updatePrev();
        }
        if (pos.x < 0) {
            pos.x = width;
            updatePrev();
        }
        if (pos.y > height) {
            pos.y = 0;
            updatePrev();
        }
        if (pos.y < 0) {
            pos.y = height;
            updatePrev();
        }
    };

    return {
        update,
        follow,
        show,
        edges
    };
};

const PerlinNoise: React.FC = () => {
    const [speed, setSpeed] = useState(0.1); // Controls the zoff increment (speed)
    const [step, setStep] = useState(1); // Controls how quickly t increases (step)
    const [isPlaying, setIsPlaying] = useState(true); // Play/pause control

    const p5Ref = useRef<p5Types | null>(null); // Store p5 instance reference

    let scl = 20;
    let cols: number, rows: number;
    let zoff = 0;
    let particles: any[] = [];
    let flowfield: p5Types.Vector[];
    let t = 0; // count the loops

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
        cols = p5.floor(p5.width / scl);
        rows = p5.floor(p5.height / scl);
        flowfield = new Array(cols * rows);

        p5.background(3, 78, 154);

        for (let i = 0; i < 5000; i++) {
            particles[i] = Particle(p5, p5.width, p5.height, scl);
        }

        p5Ref.current = p5; // Save the p5 instance for reference
    };

    const draw = (p5) => {
        console.log(t);
        t += step;

        let yoff = 0;
        for (let y = 0; y < rows; y++) {
            let xoff = 0;
            for (let x = 0; x < cols; x++) {
                let index = (x + y * cols);
                let angle = p5.noise(xoff, yoff, zoff) * p5.TWO_PI;
                let v = p5.constructor.Vector.fromAngle(angle);
                v.setMag(0.1);
                flowfield[index] = v;
                xoff += step;
                p5.stroke(0, 50);
            }
            yoff += step;
        }

        zoff += speed; // Control speed of the noise

        for (const element of particles) {
            element.follow(flowfield, cols);
            element.update();
            element.edges();
            element.show();
        }

        if (t >= 6000) {
            p5.noLoop(); // Stop looping when the condition is met
        }
    };

    // Function to toggle play/pause using loop() and noLoop()
    const togglePlay = () => {
        if (p5Ref.current) {
            if (isPlaying) {
                p5Ref.current.noLoop(); // Stop the draw loop
            } else {
                p5Ref.current.loop(); // Restart the draw loop
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <label>
                    Speed:
                    <input
                        type="range"
                        min="0"
                        max="0.5"
                        step="0.01"
                        value={speed}
                        onChange={(e) => setSpeed(parseFloat(e.target.value))}
                    />
                    {speed.toFixed(2)}
                </label>

                <label style={{ marginLeft: '20px' }}>
                    Step:
                    <input
                        type="range"
                        min="0.1"
                        max="10"
                        step="0.1"
                        value={step}
                        onChange={(e) => setStep(parseFloat(e.target.value))}
                    />
                    {step.toFixed(1)}
                </label>

                <button onClick={togglePlay} style={{ marginLeft: '20px' }}>
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
            </div>

            <Sketch setup={setup} draw={draw} />
        </div>
    );
};

export default PerlinNoise;
